<template>
  <div>
    <el-scrollbar>
      <el-main>
        <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="200px"
                 class="demo-ruleForm form-container"
                 style="margin-top: 20px; width: 99%;">
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称" prop="companyName">
                <template slot="label">
                  <span>企业名称</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.companyName"
                  controls-position="right"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份" prop="dateAndTime">
                <el-date-picker
                  v-model="dataForm.dateAndTime"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :disabled="type === 'view'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dataForm.updateTime"
                  type="date"
                  disabled="true"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安全工时" prop="safeWorkHours">
                <el-input
                  v-model="dataForm.safeWorkHours"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.safeWorkHoursS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月安全生产投入（万元）" prop="safeInvest">
                <el-input
                  v-model="dataForm.safeInvest"
                  controls-position="right"
                  maxlength="10"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.safeInvestS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月组织开展安全培训次数" prop="trainTimes">
                <el-input
                  v-model="dataForm.trainTimes"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.traintimesS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月参加安全培训人数" prop="trainPeopleCnt">
                <el-input
                  v-model="dataForm.trainPeopleCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.trainPeopleCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月检查数" prop="checkCnt">
                <el-input
                  v-model="dataForm.checkCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.checkCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月排查出安全隐患数量" prop="hdCnt">
                <el-input
                  v-model="dataForm.hdCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.hdCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其中排查出重大隐患数量" prop="majorHDCnt">
                <el-input
                  v-model="dataForm.majorHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.majorHDCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月整改完成数量" prop="rectifiedHdCnt">
                <el-input
                  v-model="dataForm.rectifiedHdCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.rectifiedHdCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月应急演练次数" prop="emergencDrillCnt">
                <el-input
                  v-model="dataForm.emergencDrillCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.emergencyDrillCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button v-if="type != 'view'" type="primary" @click="headSave">保 存</el-button>
    </div>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, detail} from "@/api/report/safetyProductionMonthly";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        dateSource: "fill",
      },
      nodeId: '',
      formLoading: false,
      processTemplateKey: "monthlySafetyProductionStatistics",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      rules: {
        dateAndTime: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        safeWorkHours: [
          {required: true, message: '请输入安全工时', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        safeInvest: [
          { required: true, message: '请输入本月安全生产投入（万元）', trigger: 'blur' },
          { pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位' }
        ],
        trainTimes: [
          {required: true, message: '请输入本月企业开展安全培训次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        trainPeopleCnt: [
          {required: true, message: '请输入本月参加安全培训人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        checkCnt: [
          {required: true, message: '请输入本月检查数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        hdCnt: [
          {required: true, message: '请输入本月排查出安全隐患数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDCnt: [
          {required: true, message: '请输入重大隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        rectifiedHdCnt: [
          {required: true, message: '请输入本月整改完成数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        emergencDrillCnt: [
          {required: true, message: '请输入本月应急演练次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: "提交",
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: 'submit',
          }
        );
      }
      return result;
    },
    formColumn() {
      return [
        {
          label: "组织名称",
          prop: 'companyName',
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          readonly: true,
        },
        {
          label: "统计月份",
          prop: 'dateAndTime',
          type: "month",
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          disabled: true,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "统计月份",
              trigger: "blur"
            }
          ],
        },
        {
          placeholder: false,
          label: "安全工时",
          prop: 'safeWorkHours',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "安全工时",
              trigger: "blur"
            }
          ],
        },
        {
          placeholder: false,
          label: "本月安全生产投入（万元）",
          prop: 'safeInvest',
          labelWidth: 240,
          span: 12,
          maxlength: 10,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月安全生产投入",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月组织开展安全培训次数",
          prop: 'trainTimes',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月组织开展安全培训次数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月参加安全培训人数",
          prop: 'trainPeopleCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月参加安全培训人数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月检查数",
          prop: 'checkCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit'].includes(this.type),
          type: 'input',
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月检查数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月排查出安全隐患数量",
          prop: 'hdCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月排查出安全隐患数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "其中排查出重大隐患数量",
          prop: 'majorHDCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "其中排查出重大隐患数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月整改完成数量",
          prop: 'rectifiedHdCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月整改完成数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月应急演练次数",
          prop: 'emergencDrillCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit'].includes(this.type),
          min: 0,
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月应急演练次数",
              trigger: "blur"
            }
          ],
        }
      ]
    },
  },
  created() {
    this.type = this.rowQuery.type;
    let row = JSON.parse(this.rowQuery.row);
    this.dataForm.companyName = row.unitName
    this.dataForm.unitCode = row.unitCode
    if(row.id && row.id != -1){
      this.dataForm.id = row.id
      this.initData()
    }else {
      this.dataForm = row;
    }
  },
  methods: {
    cancel() {
      this.$emit('closeDialog')
    },
    initData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.status == -1) {
          item.status = null
        }
        item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHours
        item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
        item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
        item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
        item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
        item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
        item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
        item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
        item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
        item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHoursS
        item.safeInvestS == -1 ? item.safeInvestS = null : item.safeInvestS
        item.traintimesS == -1 ? item.traintimesS = null : item.traintimesS
        item.trainPeopleCntS == -1 ? item.trainPeopleCntS = null : item.trainPeopleCntS
        item.checkCntS == -1 ? item.checkCntS = null : item.checkCntS
        item.hdCntS == -1 ? item.hdCntS = null : item.hdCntS
        item.rectifiedHdCntS == -1 ? item.rectifiedHdCntS = null : item.rectifiedHdCntS
        item.majorHDCntS == -1 ? item.majorHDCntS = null : item.majorHDCntS
        item.emergencyDrillCntS == -1 ? item.emergencyDrillCntS = null : item.emergencyDrillCntS
        this.dataForm = item;
      })
    },
    headSave(cancel = false) {
      this.formLoading = true;
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          this.dataForm.statType = "SAFE"
          this.dataForm.dateSource = "fill"
          update({
            ...this.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            }).finally(() => {
          })
        }
      })
      this.formLoading = false;
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
