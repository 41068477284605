<template>
  <div class="personnelHolography-detail">
    <head-layout
      head-title="相关方组织全息"
      :head-btn-options="headBtnOptions"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="flex-container" style="height: calc(100% - 52px)">
      <el-tabs
        tab-position="left"
        type="border-card"
        v-model="activeName"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="组织基本信息" name="orgBaseInfo"></el-tab-pane>
        <el-tab-pane label="项目记录" name="projectRecord"></el-tab-pane>
        <el-tab-pane label="企业证照" name="enterpriseLicence"></el-tab-pane>
        <el-tab-pane label="黑名单" name="blackList"></el-tab-pane>
      </el-tabs>
      <orgBaseInfo class="flex-one" v-if="activeName == 'orgBaseInfo'" ref="orgBaseInfo"></orgBaseInfo>
      <projectRecord class="flex-one" v-if="activeName == 'projectRecord'" ref="projectRecord"></projectRecord>
      <enterpriseLicence class="flex-one" v-if="activeName == 'enterpriseLicence'" ref="enterpriseLicence"></enterpriseLicence>
      <blackList class="flex-one" v-if="activeName == 'blackList'" ref="blackList"></blackList>
    </div>
  </div>
</template>

<script>
import projectRecord from "../personnelHolography/components/projectRecord.vue";
import orgBaseInfo from "./components/orgBaseInfo.vue";
import enterpriseLicence from "./components/enterpriseLicence.vue";
import blackList from "../relatedPersonnellHolography/components/blackList.vue";

export default {
  components: {
    orgBaseInfo,
    projectRecord,
    enterpriseLicence,
    blackList,
  },
  data() {
    return {
      activeName: "orgBaseInfo",
      formId: "",
      pageType: "",
      pageCompanyCode: "",
      opType: ""
    };
  },
  mounted() {
    let type = this.$route.query.type;
    let id = this.$route.query.id;
    this.opType = this.$route.query.opType
    this.pageType = type;
    this.formId = id;
    this.pageCompanyCode = this.$route.query.companyCode;
    this.handleTabClick();
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleTabClick() {
     this.$nextTick(()=>{
       if (this.activeName == 'orgBaseInfo') {
         this.$refs[this.activeName].init(this.formId,this.opType);
       } else {
         this.$refs[this.activeName].init(this.pageCompanyCode, this.pageType,this.opType);
       }
     })
    },
  },
};
</script>
<style scoped lang="scss">
.personnelHolography-detail {
  width: 100%;
  height: 100%;
  ::v-deep .el-tabs__header.is-left {
    min-width: 130px ;
    margin-right: 0;
  }
  ::v-deep .el-tabs--border-card {
    background-color: #f5f7fa;
  }
  ::v-deep .el-tabs__content {
    display: none;
  }

  .formContentBox {
    padding: 12px 0;
    height: calc(100% - 76px);
  }
}
</style>
