<template>
  <div>
    <head-layout
       v-if="opType == 'edit'"
      :head-btn-options="headBtnOptions"
      @head-upload="handleUpload"
    />
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    >
    </grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :grid-row-btn="gridRowBtn"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      @row-edit="rowEdit"
      @row-download="rowDownload"
      @row-remove="rowRemove"
    >
      <template #fileName="{ row, index }">
        <el-link type="primary" @click="rowView(row, index)">{{
          getFileName(row.fileName)
        }}</el-link>
      </template>
    </grid-layout>
    <el-dialog title="证件查看" :visible.sync="imageDiavlog">
      <pdf :src="fileUrl.src" v-if="fileUrl.type == 'pdf'"></pdf>
      <el-image :src="fileUrl.src" v-else></el-image>
    </el-dialog>
    <cedForm ref="cedForm" @callback="callback" />
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import pdf from "@/components/pdf/pdf.vue";
import cedForm from "@/views/business/responsibility/relatedCompanyEnterprise/cedForm.vue";
import {
  relatedEnterprisePage,
  relatedEnterpriseRemove,
} from "@/api/responsibility/relatedCompanyEnterprise";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
export default {
  components: {
    GridLayout,
    pdf,
    cedForm,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      searchColumns: [
        {
          prop: "fileName",
          placeholder: "证件名称",
          span: 4,
        },
        // {
        //   prop: "certificateType_search",
        //   placeholder: "请选择类型",
        //   span: 4,
        //   search: true,
        //   type: "cascader",
        //   dicUrl:
        //     "/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   checkStrictly: true,

        // },
        // {
        //   prop: "startDate",
        //   placeholder: "发证日期",
        //   span: 4,
        //   type: "date-format",
        // },
        // {
        //   prop: "certificateState",
        //   placeholder: "证件状态",
        //   span: 4,
        //   search: true,
        //   type: "select",
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=certificate_state",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        // },
      ],
      companyCode: "",
      fileUrl: "",
      imageDiavlog: false,
      opType: "",
    };
  },
  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        downloadBtn: this.vaildData(
          this.permission.eduquestions_download,
          true
        ),
        settingBtn: this.vaildData(this.permission.eduquestions_setting, true),
      };
    },
    tableOptions() {
      return {
        menu: this.opType == "edit",
        selection: false,
        column: [
          {
            label: "证件名称",
            prop: "fileName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "证件类型",
            prop: "certificateType",
            align: "center",
            cell: true,
            type: "cascader",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
              checkStrictly: true,
            },
          },
          {
            label: "证件编号",
            prop: "certificateCode",
            align: "center",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "发证单位",
            prop: "certificateUnit",
            align: "center",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "证件状态",
            prop: "certificateState",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=certificate_status`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "发证日期",
            prop: "startDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "发证时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "到期日期",
            prop: "endDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "到期时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
        ],
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.opType == "edit") {
        buttonBtn.push({
          label: "上传证照",
          emit: "head-upload",
          type: "button",
          icon: "",
          btnOptType: "uploadX",
        });
      }
      return buttonBtn;
    },
    gridRowBtn(row) {
      let buttonBtn = [];
      if (this.permissionList.downloadBtn) {
        buttonBtn.push({
          label: "下载",
          emit: "row-download",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },
  methods: {
    init(id, pageType, opType) {
      this.companyCode = id;
      this.opType = opType;
      this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.companyCode = this.companyCode;

      relatedEnterprisePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    rowView(row) {
      let pos = row.files.lastIndexOf(".");
      const type = row.files.slice(pos + 1, row.files.length);
      this.fileUrl = { src: row.files, name: row.fileName, type };
      this.imageDiavlog = true;
    },
    getFileName(name) {
      let pos = name.lastIndexOf(".");
      return name.slice(0, pos);
    },
    handleUpload() {
      this.$refs.cedForm.init({
        companyCode: this.companyCode,
      });
    },
    rowEdit(row, index) {
      this.$refs.cedForm.init(row);
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (row.id) {
          relatedEnterpriseRemove(row.id).then(() => {
            this.onLoad(this.page);
            this.$message.success("操作成功！");
          });
        } else {
          this.tableData.splice(index, 0);
        }
      });
    },

    rowDownload(row) {
      downloadFileBlob(row.files, row.fileName);
    },
    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
  },
};
</script>
<style scoped lang="scss"></style>
